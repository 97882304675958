import React from "react"
import "./BannerBox.scss"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"

const BannerBox = prop => {
  return (
    <div className="banner-box">
      <div className="banner-box__title">
        <div className="customBox">
          { prop.item.title && (
            (typeof prop.item.title != "string") & (prop.item.title.length > 1) ? (
              <div className="block">
                <h1 id="title-h1-include-span-tag">
                  <span className="d-block">{prop.item.title[0]}</span>
                  <span className="d-block">{prop.item.title[1]}</span>
                  <span className="d-block">{prop.item.title[2]}</span>
                  <span className="d-block">{prop.item.title[3]}</span>
                </h1>
                {prop.item.content && (
                  <div className="banner-box__content">
                    <HandleOrphan>
                      <p>{prop.item.content}</p>
                    </HandleOrphan>
                  </div>
                )}
              </div>
            ) : (
              <div className="block">
                <h1 id="heading-title">{prop.item.title}</h1>

                {prop.item.content && (
                  <div className="banner-box__content">
                    <HandleOrphan>
                      <p>{prop.item.content}</p>
                    </HandleOrphan>
                  </div>
                )}
              </div>
            )
          )}

          { prop.item.titleH2 && (
              <div className="block">
                <h2 className="footer-banner-title">
                  <span className="d-block">{prop.item.titleH2[0]}</span>
                  <span className="d-block">{prop.item.titleH2[1]}</span>
                  <span className="d-block">{prop.item.titleH2[2]}</span>
                  <span className="d-block">{prop.item.titleH2[3]}</span>
                </h2>
                {prop.item.content && (
                  <div className="banner-box__content">
                    <HandleOrphan>
                      <p>{prop.item.content}</p>
                    </HandleOrphan>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default BannerBox
