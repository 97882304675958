import React from "react"
import "./style.scss"
import background from "../../../assets/img/OurGoal/background.jpg"
import CustomAccordion from "../../Accordion"

const data = [
  {
    title: "What are the different names of a CV parser?",
    description:
      "Apart from CV parser, people commonly type for ‘Resume/CV Extractor,’ ‘Resume Extraction Engine,’ or ‘Resume Parser’ when searching for a tool to extract CV data.",
  },
  {
    title:
      "How Neurond’s CV Parser differentiates itself from other resume parsers?",
    description:
      "As mentioned earlier, while other resume parsing software authorizes numerous CV file types and languages, Neurond’s CV Parser focuses on accuracy. In other words, we ensure the extracted data is over 90% correct.",
  },
  {
    title: "Who should use Neurond’s CV Parser?",
    description:
      "Any business looking for a simple yet powerful solution to automate the hiring process can benefit from our resume parser. You can be an enterprise, staffing firm, or even an applicant tracking system to make the most of CV data parsing and extraction. ",
  },
  {
    title: "Which document formats does a Neurond’s CV Parser support?",
    description:
      "Neuron's CV Parser enables you to parse various resume file formats such as PDF, RTF, and TXT. We’re in the progress of upgrading the tool to perform on rare file types like ODT, HTM, and HTML.",
  },
  {
    title: "How does a CV parser contribute to the hiring process?",
    description:
      "After a candidate land on your career page, he’ll submit the resume to apply for the job. Once the CV is uploaded to the company’s ATS, Neurond’s Parser will read through the information, analyze, and structure this data into fields. As a result, recruiters are able to filter candidates by some specific information to find the perfect match with ease.",
  },
  {
    title: "Where does a CV Parser store data?",
    description:
      "Resume parsers shouldn’t store the processed data, and no CV parser is doing that. Instead, this private personal information will be saved in the SaaS Cloud of the parsing services.",
  },
]

function PackagesForm() {
  return (
    <div
      className="form center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <h2>Frequently Asked Questions</h2>
      <CustomAccordion data={data} />
    </div>
  )
}

export default PackagesForm
