import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Banner from "../../../components/Banner/Banner"
import Layout from "../../../components/layout"
import PackageCard from "../../../components/CVParsing/PackageCard"
import SEO from "/src/components/seo"
import "./packages.scss"
import PackagesForm from "../../../components/CVParsing/PackagesForm"
import ContactFormModal from "../../../components/CVParsing/ContactFormModal"

const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/HomePage/home_page_slider.webp`

const bannerData = {
  title: ["Neurond’s Resume Parser Pricing "],
  content: [
    "We offer a variety of purchasing options for our CV parsing API solution.",
  ],
}
const cardData = {
  title: {
    h2: "Free Trial",
    p: "Perfect for getting started with our API",
  },
  content: {
    h3: "Try For",
    price: "0 $",
    p: "Try parsing through our Demo UI and/or through our developer API",
  },
  footer: "START NOW",
}

const cardData1 = {
  title: {
    h2: "Advanced Plan",
    p: "For testing full integration in your workflow",
  },
  content: {
    h3: "Contact us for customized pricing",
    p: "We provide scalable cloud and on-premise pricing models",
  },
  footer: "CONTACT US",
}

const CVParsingPage = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <Layout>
      <SEO title={t("cv_parsing")} />
      <div className="margin-bot">
        <Banner item={bannerData} backgroundImage={bannerImg} />
        <div class="ellipse" />
      </div>
      <section>
        <div className="center-package-card flex">
          <PackageCard cardData={cardData} />
          <PackageCard
            cardData={cardData1}
            handleClickButton={() => setOpen(true)}
          />
        </div>
      </section>
      <section>
        <PackagesForm />
      </section>
      <ContactFormModal open={open} handleClose={() => setOpen(false)} />
    </Layout>
  )
}

export default CVParsingPage
