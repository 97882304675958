import React from "react"
import "./style.scss"

const PackageCard = ({
  cardData: { title, content, footer },
  handleClickButton,
}) => {
  return (
    <div className="PK-card-container center">
      <div className="PK-card-header">
        <h2>{title.h2}</h2>
        <p>{title.p}</p>
      </div>
      <div className="PK-card-content">
        <h3>{content.h3}</h3>
        {content.price && <h2>{content.price}</h2>}
        <p>{content.p}</p>
      </div>
      <div className="PK-card-footer" onClick={handleClickButton}>
        {footer}
      </div>
    </div>
  )
}

export default PackageCard
