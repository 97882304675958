import React from "react"
import { Link } from "gatsby"
// import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import CustomButton from "../../components/button/CustomButton"
import "./Banner.scss"
import BannerBox from "../BannerBox/BannerBox"

const Banner = ({ item, backgroundImage }) => {
  return (
    <section
      className="banner"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay d-flex">
        <div className="banner-text wrapper">
          <BannerBox item={item} />
          {item.buttonContent && (
            <Link to="/careers">
              <CustomButton content={item.buttonContent} />
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}
export default Banner
