import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Logo from "../../../assets/img/Neurond.png"
import "./style.scss"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Countries from '../../../data/country.json'

const defineError = {
  FIRST_NAME: {
    name: "FIRST_NAME",
    pattern: /^[A-Za-z]*$/,
    errorValue: "Please enter plain text only"
  },
  LAST_NAME: {
    name: "LAST_NAME",
    pattern: /^[A-Za-z]*$/,
    errorValue: "Please enter plain text only"
  },
  EMAIL: {
    name: "EMAIL",
    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    errorValue: "Invalid email format"
  },
  PHONE_NUMBER: {
    name: "PHONE_NUMBER",
    pattern: /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{3,14}$/im,
    errorValue: "Invalid phone number format"
  }
}

const ContactFormModal = ({ open, handleClose }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/ai.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [submitted, setSubmitted] = useState(false)
  const [errorForm, setErrorForm] = useState({})
  let flag = false

  const handleChangeInput = (e, nameField) => {
    const value = e.target.value

    if (value.match(defineError[nameField].pattern) && value.trim()) {
      setErrorForm(prevState => ({
        ...prevState,
        [nameField]: null
      }))
    } else {
      setErrorForm(prevState => ({
        ...prevState,
        [nameField]: defineError[nameField].errorValue
      }))
    }
  }

  const checkDisabled = () => {
    return !!Object.values(errorForm).join('')
  }

  return (
    <Dialog
      className="contact-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-contact"
    >
      <DialogTitle id="form-dialog-contact">
        <div className="info-section__content__logo">
          <img alt="logo" src={Logo} />
        </div>
      </DialogTitle>
      <DialogContent>
        {submitted ? (
          <div className="center">
            <Img fluid={data.file.childImageSharp.fluid} />
            <h2>Thank you.</h2>
            <h2>You’II hear from us soon.</h2>
          </div>
        ) : (
          <>
            <DialogContentText className="center">
              Leave your contact here and we’II reply to you as soon as
              possible.
            </DialogContentText>
            <iframe
              title="redirect"
              name="hidden_iframe"
              id="hidden_iframe"
              style={{ display: "none" }}
              onLoad={() => {
                if (flag) setSubmitted(true)
              }}
            />
            <form
              id="contact"
              action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSd-cm-lKAbEo_-7N1gzWxGgqy9l3YFV0h32HyuXRB5EQkNR2g/formResponse"
              target="hidden_iframe"
              onSubmit={() => (flag = true)}
            >
              <TextField
                error={errorForm[defineError.FIRST_NAME.name]}
                required
                margin="dense"
                id="input1"
                label="Enter First Name"
                fullWidth
                name="entry.980203553"
                helperText={errorForm[defineError.FIRST_NAME.name]}
                onChange={(e) => handleChangeInput(e, defineError.FIRST_NAME.name)}
                onBlur={e => {
                  e.target.value = e.target.value?.trim()
                }}
              />
              <TextField
                error={errorForm[defineError.LAST_NAME.name]}
                required
                margin="dense"
                id="input2"
                label="Enter Last Name"
                fullWidth
                name="entry.1825065447"
                helperText={errorForm[defineError.LAST_NAME.name]}
                onChange={(e) => handleChangeInput(e, defineError.LAST_NAME.name)}
                onBlur={e => {
                  e.target.value = e.target.value?.trim()
                }}
              />
              <TextField
                error={errorForm[defineError.EMAIL.name]}
                required
                margin="dense"
                id="input3"
                label="Enter Email Address"
                type="email"
                fullWidth
                name="entry.141277591"
                helperText={errorForm[defineError.EMAIL.name]}
                onChange={(e) => handleChangeInput(e, defineError.EMAIL.name)}
                onBlur={e => {
                  e.target.value = e.target.value?.trim()
                }}
              />
              <TextField
                error={errorForm[defineError.PHONE_NUMBER.name]}
                required
                margin="dense"
                id="input4"
                label="Phone Number"
                fullWidth
                name="entry.1911317265"
                helperText={errorForm[defineError.PHONE_NUMBER.name]}
                onChange={(e) => handleChangeInput(e, defineError.PHONE_NUMBER.name)}
                onBlur={e => {
                  e.target.value = e.target.value?.trim()
                }}
              />
              <TextField
                required
                margin="dense"
                id="input5"
                label="Company Name"
                fullWidth
                name="entry.1841035326"
                onBlur={e => {
                  e.target.value = e.target.value?.trim()
                }}
              />
              <TextField
                required
                margin="dense"
                id="input6"
                select
                label="Country"
                fullWidth
                name="entry.1310851694"
                className="custom-select-country"
              >
                {Countries.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                id="input7"
                label="Your Job Title"
                fullWidth
                name="entry.834389359"
                onBlur={e => {
                  e.target.value = e.target.value?.trim()
                }}
              />
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions className="action-btn">
        {submitted ? (
          <button onClick={handleClose} color="primary">
            Close
          </button>
        ) : (
          <>
            <button
              onClick={() => {
                handleClose()
                setErrorForm({})
              }}
              color="primary"
            >
              Cancel
            </button>
            <button disabled={checkDisabled()} type="submit" form="contact" color="primary">
              Subscribe
            </button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ContactFormModal
